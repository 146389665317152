import request from "@/api/request";
/*
 *左侧按钮
 * */

export function leftbutton(data) {
  return request({
    url: "api/member/left_button",
    method: "post",
    data
  });
}
/*
 *背景
 * */

export function leftbackground(data) {
  return request({
    url: "api/member/left_background",
    method: "post",
    data
  });
}
/*
 *背景初始值
 * */

export function layoutbg(data) {
  return request({
    url: "api/member/layout_bg",
    method: "post",
    data
  });
}
/*
 *背景修改
 * */

export function layoutbgup(data) {
  return request({
    url: "api/member/layout_bg_up",
    method: "post",
    data
  });
}
/*
 *按钮分类
 * */

export function hclass(data) {
  return request({
    url: "api/hbutton/hclass",
    method: "post",
    data
  });
}
/*
 *按钮列表
 * */

export function hindex(data) {
  return request({
    url: "api/hbutton/hindex",
    method: "post",
    data
  });
}
/*
 *链接按钮添加
 * */

export function relevanceup(data) {
  return request({
    url: "api/hbutton/relevance_up",
    method: "post",
    data
  });
}
/*
 *获得添加的信息
 * */

export function relevance(data) {
  return request({
    url: "api/hbutton/relevance",
    method: "post",
    data
  });
}
/*
 *获得添加的信息
 * */

export function inboxadd(data) {
  return request({
    url: "api/hbutton/inbox_add",
    method: "post",
    data
  });
}
/*
 *删除数据
 * */

export function relevancedel(data) {
  return request({
    url: "api/hbutton/relevance_del",
    method: "post",
    data
  });
}
/*
 *记录点击次数
 * */
export function relevancedj(data) {
  return request({
    url: "api/hbutton/relevance_dj",
    method: "post",
    data
  });
}
/*
 *举报
 * */
export function report(data) {
  return request({
    url: "api/stationery/report",
    method: "post",
    data
  });
}
/*
 *手机二维码
 * */
export function qrcode(data) {
  return request({
    url: "api/stationery/qr_code",
    method: "post",
    data
  });
}
/*
 *批量修改
 * */
export function weight(data) {
  return request({
    url: "api/hbutton/weight",
    method: "post",
    data
  });
}

/*
 *邮箱注册
 * */
export function subscription(data) {
  return request({
    url: "api/stationery/subscription",
    method: "post",
    data
  });
}
/*
 *添加商品
 * */
export function hgoods(data) {
  return request({
    url: "api/hbutton/hgoods",
    method: "post",
    data
  });
}
/*
 *商品列表
 * */
export function hgoodslist(data) {
  return request({
    url: "api/hbutton/hgoods_list",
    method: "post",
    data
  });
}

/*
 *修改商品
 * */
export function hgoodsup(data) {
  return request({
    url: "api/hbutton/hgoods_up",
    method: "post",
    data
  });
}

/*
 *删除商品
 * */
export function hgoodsdel(data) {
  return request({
    url: "api/hbutton/hgoods_del",
    method: "post",
    data
  });
}
/*
 *提交商品
 * */
export function orderadd(data) {
  return request({
    url: "api/hbutton/order_add",
    method: "post",
    data
  });
}
/*
 *订单列表
 * */
export function horderlist(data) {
  return request({
    url: "api/hbutton/horder_list",
    method: "post",
    data
  });
}
/*
 *页面数据统计
 * */
export function statisticanalysisone(data) {
  return request({
    url: "api/stationery/statistic_analysis_one",
    method: "post",
    data
  });
}
/*
 *外页面数据统计
 * */
export function statisticanalysis(data) {
  return request({
    url: "api/member/statistic_analysis",
    method: "post",
    data
  });
}
/*
 *外页面数据统计
 * */
export function statisticanalysisymd(data) {
  return request({
    url: "api/member/statistic_analysis_ymd",
    method: "post",
    data
  });
}
/*
 *手机号登录
 * */
export function hphone(data) {
  return request({
    url: "api/stationery/hphone",
    method: "post",
    data
  });
}

/*
 *点赞/收藏 列表
 * */
export function favoritelist(data) {
  return request({
    url: "api/stationery/favorite_list",
    method: "post",
    data
  });
}
/*
 *收藏
 * */
export function favorite(data) {
  return request({
    url: "api/stationery/favorite",
    method: "post",
    data
  });
}
/*
 *点赞
 * */
export function praise(data) {
  return request({
    url: "api/stationery/praise",
    method: "post",
    data
  });
}

/*
 *h5模板
 * */
export function template(data) {
  return request({
    url: "api/hbutton/template",
    method: "post",
    data
  });
}

/*
 *h5模板更换
 * */
export function templateselect(data) {
  return request({
    url: "api/hbutton/template_select",
    method: "post",
    data
  });
}
/*
 *信使服务第一层列表
 * */
export function xinlist(data) {
  return request({
    url: "api/hbutton/xin_list",
    method: "post",
    data
  });
}
/*
 *信使服务第一层列表
 * */
export function xinindex(data) {
  return request({
    url: "api/hbutton/xin_index",
    method: "post",
    data
  });
}
